import React from "react";

class TrainerCard extends React.Component {
  render() {
    return (
      <div class="trainer-card shrink-animation">
        <a href={this.props.link}>
          <div class="trainer-card-text">
            <h2>{this.props.title}</h2>
            <p>{this.props.description}</p>
          </div>
          <img src={this.props.img} alt="Hotkey trainer preview." />
        </a>
      </div>
    );
  }
}

export default TrainerCard;
