import React from "react";
import "../css/shared.css";
import "../css/hotkey-editor.css";
import HotkeyEditorTableRow from "./HotkeyEditorTableRow";
import { NavigationActions } from "../utils/HotkeyNames";

class HotkeyEditorNavigationTable extends React.Component {
  render() {
    return (
      <table class="hotkey-editor-table">
        <td>
          <HotkeyEditorTableRow
            name={NavigationActions.EconomicBuildings}
            hotkey={this.props.hotkeyMap[NavigationActions.EconomicBuildings]}
            clickHandler={this.props.selectionHandler}
          />
          <HotkeyEditorTableRow
            name={NavigationActions.MilitaryBuildings}
            hotkey={this.props.hotkeyMap[NavigationActions.MilitaryBuildings]}
            clickHandler={this.props.selectionHandler}
          />
        </td>
        <td>
          <HotkeyEditorTableRow
            name={NavigationActions.MoreBuildings}
            hotkey={this.props.hotkeyMap[NavigationActions.MoreBuildings]}
            clickHandler={this.props.selectionHandler}
          />
          <HotkeyEditorTableRow
            name={NavigationActions.Cancel}
            hotkey={this.props.hotkeyMap[NavigationActions.Cancel]}
            clickHandler={this.props.selectionHandler}
          />
        </td>
      </table>
    );
  }
}

export default HotkeyEditorNavigationTable;
