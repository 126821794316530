import React from "react";
import gray from "../assets/gray.png";

class BuildingPrompt extends React.Component {
  render() {
    let userInputKeys = [];
    for (const key of this.props.userInput) {
      userInputKeys.push(<h5>{key}</h5>);
    }

    return (
      <div class="building-prompt">
        <div>
          <img src={this.props.icon || gray} alt="Building to press the hotkey for." />
          <div class="building-prompt-key-slots">
            {userInputKeys.length > 0 ? userInputKeys[0] : <div></div>}
            {userInputKeys.length > 1 ? userInputKeys[1] : <div></div>}
          </div>
        </div>
      </div>
    );
  }
}

export default BuildingPrompt;
