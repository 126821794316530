import React from "react";

class Results extends React.Component {
  displayResultsText() {
    if (this.props.hpm["matches"] === 1) {
      return `You typed ${this.props.hpm["matches"]} correct hotkey per minute!`;
    } else {
      return `You typed ${this.props.hpm["matches"]} correct hotkeys per minute!`;
    }
  }

  render() {
    if (this.props.showResults && this.props.hpm) {
      let accuracy =
        (this.props.hpm["matches"] / (this.props.hpm["matches"] + this.props.hpm["fails"])) * 100;
      if (isNaN(accuracy)) accuracy = 0;
      return (
        <div class="trainer-results">
          <h3>
            RESULTS - Speed: {this.props.hpm["matches"]} HPM | Accuracy: {accuracy.toFixed(1)}%
          </h3>
          {/* <h2>{this.displayResultsText()}</h2> */}
        </div>
      );
    }

    return <div class="trainer-results"></div>;
  }
}

export default Results;
