import React from "react";
import { Helmet } from "react-helmet";
import TrainerCard from "./TrainerCard";
import "../css/trainer.css";
import "../css/shared.css";
import villagerTrainerPreviewImg from "../assets/villager-sc.png";
// import buildingSelectionTrainerPreviewImg from "../assets/select-sc.png";
import NavBar from "./NavBar";

class Trainer extends React.Component {
  render() {
    return (
      <div class="page-container">
        {/* HTML Meta tags */}
        <Helmet>
          <title>aoehippo | Feedback</title>
          <meta
            name="description"
            content="Thank you for using the hotkey training tool. Let us know how we can improve!"
          />
        </Helmet>
        {/* Naviation bar at the top of the page */}
        <NavBar />
        <div class="trainer main-content">
          <h1 class="trainer-selection">Select a Hotkey Trainer</h1>
          <div class="card-container">
            <TrainerCard
              title="Villager Hotkeys"
              description="Hotkeys to make villagers construct economic or military buildings"
              img={villagerTrainerPreviewImg}
              link="/villager-trainer"
            />
            {/* <TrainerCard
              title="Building Selection Hotkeys"
              description='Hotkeys to quickly "jump to" or cycle between between different buildings'
              img={buildingSelectionTrainerPreviewImg}
              link="/building-selection-trainer"
            /> */}
          </div>
        </div>
        {/* Footer */}
        <footer class="main-footer">
          <h3>@ 2022 aoehippo</h3>
        </footer>
      </div>
    );
  }
}

export default Trainer;
