import React from "react";
import { Helmet } from "react-helmet";
import "../css/shared.css";
import "../css/disclaimer.css";
import NavBar from "./NavBar";

class Disclaimer extends React.Component {
  render() {
    return (
      <div class="page-container">
        {/* HTML Meta tags */}
        <Helmet>
          <title>aoehippo | Disclaimer</title>
          <meta
            name="description"
            content="Read our legal disclaimer for using Age of Empires game content."
          />
        </Helmet>
        {/* Naviation bar at the top of the page */}
        <NavBar />
        <div class="disclaimer main-content">
          <p>
            Age of Empires © Microsoft Corporation. Aoehippo was created under Microsoft's{" "}
            <a href="https://www.xbox.com/en-US/developers/rules">"Game Content Usage Rules"</a>{" "}
            using assets from Age of Empires, and it is not endorsed by or affiliated with
            Microsoft.
          </p>
        </div>
        <footer class="main-footer">
          <h3>--- aoehippo ---</h3>
        </footer>
      </div>
    );
  }
}

export default Disclaimer;
