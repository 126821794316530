import React from "react";
import "../css/shared.css";
import "../css/hotkey-editor.css";
import {
  EconomicBuildings,
  MilitaryBuildings,
  NavigationActions,
  isEconomicBuilding,
  isMilitaryBuilding,
  isNavigationAction,
} from "../utils/HotkeyNames";
import { de_hotkeys } from "../hotkeys/villager-build/de";
import { hd_hotkeys } from "../hotkeys/villager-build/hd";
import { hera_hotkeys } from "../hotkeys/villager-build/hera";
import { viper_hotkeys } from "../hotkeys/villager-build/viper";
import HotkeyEditorNavigationTable from "./HotkeyEditorNavigationTable";
import HotkeyEditorBuildingTable from "./HotkeyEditorBuildingTable";
import { CgClose } from "react-icons/cg";
import { PRE_LOADED_HOTKEY_SET_NAMES } from "../utils/constants";

class HotkeyEditor extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      hotkeyMap: {},
      selectedHotkeyName: undefined,
      selectedHotkeyElement: undefined,
      profileName: undefined,
      conflictingHotkeys: new Set([]),
      defaultHotkeys: undefined,
    };

    this.selectHotkey = this.selectHotkey.bind(this);
    this.onProfileNameChanged = this.onProfileNameChanged.bind(this);
    this.onKeyPressed = this.onKeyPressed.bind(this);
    this.saveHotkeys = this.saveHotkeys.bind(this);
    this.resetHotkeys = this.resetHotkeys.bind(this);
    this.clearHotkey = this.clearHotkey.bind(this);
    this.changeDefaultHotkeys = this.changeDefaultHotkeys.bind(this);

    document.addEventListener("keydown", this.onKeyPressed);

    this.setHotkeyMap(de_hotkeys);
  }

  setHotkeyMap(root, parent = undefined) {
    // If we are at leaf
    if (typeof root === "string") {
      // eslint-disable-next-line
      this.state.hotkeyMap[root] = parent;
      return;
    }

    // If we are at subtree
    for (const node in root) {
      const value = node;
      const subtree = root[node];
      this.setHotkeyMap(subtree, value);
    }
  }

  saveHotkeys() {
    const tree = {};
    const navigation = {};

    tree[this.state.hotkeyMap[NavigationActions.EconomicBuildings]] = {};
    tree[this.state.hotkeyMap[NavigationActions.MilitaryBuildings]] = {};

    // Build hotkey tree
    for (const hotkeyName in this.state.hotkeyMap) {
      if (isEconomicBuilding(hotkeyName)) {
        tree[this.state.hotkeyMap[NavigationActions.EconomicBuildings]][
          this.state.hotkeyMap[hotkeyName]
        ] = hotkeyName;
      } else if (isMilitaryBuilding(hotkeyName)) {
        tree[this.state.hotkeyMap[NavigationActions.MilitaryBuildings]][
          this.state.hotkeyMap[hotkeyName]
        ] = hotkeyName;
      } else if (isNavigationAction(hotkeyName)) {
        navigation[this.state.hotkeyMap[hotkeyName]] = hotkeyName;
      }
    }

    let profileName = this.state.profileName;
    console.log("default", PRE_LOADED_HOTKEY_SET_NAMES)
    if (PRE_LOADED_HOTKEY_SET_NAMES.indexOf(profileName) !== -1) {
      profileName = `${profileName}(1)`;
    }

    this.props.onHotkeyEditorSave(profileName, { tree, navigation });
  }

  resetHotkeys() {
    // Clear hotkey map
    Object.keys(this.state.hotkeyMap).forEach((key) => delete this.state.hotkeyMap[key]);
    // Restore hotkey map to default
    this.setHotkeyMap(this.state.defaultHotkeys);

    // If hotkey selected, deselect it
    if (this.state.selectedHotkeyElement) {
      // eslint-disable-next-line
      this.state.selectedHotkeyElement.style.color = null;
      // eslint-disable-next-line
      this.state.selectedHotkeyElement.style.backgroundColor = null;
    }

    this.setState({ selectedHotkeyElement: undefined, selectedHotkeyName: undefined });

    // Check for conflicts
    this.checkIfHotkeyConflicts();
  }

  clearHotkey() {
    if (
      this.state.selectedHotkeyName === undefined ||
      this.state.selectedHotkeyElement === undefined
    ) {
      return;
    }

    delete this.state.hotkeyMap[this.state.selectedHotkeyName];

    // eslint-disable-next-line
    this.state.selectedHotkeyElement.style.color = null;
    // eslint-disable-next-line
    this.state.selectedHotkeyElement.style.backgroundColor = null;

    // Check for conflicts
    this.checkIfHotkeyConflicts();

    this.setState({ selectedHotkeyElement: undefined, selectedHotkeyName: undefined });
  }

  onKeyPressed(event) {
    if (!this.state.selectedHotkeyElement || !this.state.selectedHotkeyName) return;
    // Don't listen for key presses when entering profile name
    if (document.activeElement.id === "profile-text-box") return;

    const selectedElement = this.state.selectedHotkeyElement;
    const selectedHotkeyName = this.state.selectedHotkeyName;
    const keyElement = selectedElement.getElementsByClassName("hotkey")[0];

    if (keyElement) {
      // Hash table of allowed non-alphanumeric character ascii codes
      const nonAlphaChars = {
        189: "-",
        187: "=",
        8: "Backspace",
        219: "[",
        221: "]",
        220: "\\",
        186: ";",
        222: "'",
        13: "Enter",
        188: ",",
        190: ".",
        191: "/",
        192: "`",
        9: "Tab",
        32: "Space",
        36: "Home",
        46: "Delete",
        37: "Left",
        38: "Up",
        39: "Right",
        40: "Down",
        33: "PgUp",
        34: "PgDown",
      };

      let isCharValid = false;
      // Character in alphabet
      if (event.keyCode >= 65 && event.keyCode <= 90) isCharValid = true;
      // Character is numeric
      if (event.keyCode >= 48 && event.keyCode <= 57) isCharValid = true;
      // Character is allowed non-alphanumeric
      if (nonAlphaChars[event.keyCode] !== undefined) isCharValid = true;
      // Shift & Ctrl keys are being pressed simoultaneously
      if (event.ctrlKey && event.shiftKey) isCharValid = false;

      if (!isCharValid) return;

      // Get character from keycode
      let newHotkey;
      if (nonAlphaChars[event.keyCode] === undefined) {
        newHotkey = String.fromCharCode(event.keyCode);
      } else {
        newHotkey = nonAlphaChars[event.keyCode];
      }

      if (event.ctrlKey) newHotkey = "Ctrl + " + newHotkey;
      if (event.shiftKey) newHotkey = "Shift + " + newHotkey;

      // eslint-disable-next-line
      this.state.hotkeyMap[selectedHotkeyName] = newHotkey;
      keyElement.innerText = newHotkey;

      // Check for conflicts
      this.checkIfHotkeyConflicts();
    }
  }

  checkIfHotkeyConflicts() {
    // Eco: Check against eco, more buildings, and cancel hotkeys
    // Mili: Check against mili, more buildings, and cancel hotkeys
    // More or Cancel: check against everything
    // Navigation: check against navigation
    const conflicts = new Set([]);

    // Compare Economic Buildings vs Economic Buildings vs Cancel & More Buildings
    for (const buildingA in EconomicBuildings) {
      const hotkeyA = this.state.hotkeyMap[EconomicBuildings[buildingA]];
      if (hotkeyA === undefined) continue;
      for (const buildingB in EconomicBuildings) {
        if (buildingA === buildingB) continue;
        const hotkeyB = this.state.hotkeyMap[EconomicBuildings[buildingB]];
        if (hotkeyA === hotkeyB) {
          conflicts.add(EconomicBuildings[buildingA]);
        }
      }

      if (hotkeyA === this.state.hotkeyMap[NavigationActions.Cancel]) {
        conflicts.add(EconomicBuildings[buildingA]);
        conflicts.add(NavigationActions.Cancel);
      }

      if (hotkeyA === this.state.hotkeyMap[NavigationActions.MoreBuildings]) {
        conflicts.add(EconomicBuildings[buildingA]);
        conflicts.add(NavigationActions.MoreBuildings);
      }
    }

    // Compare Military Buildings vs Military Buildings vs Cancel & More Buildings
    for (const buildingA in MilitaryBuildings) {
      const hotkeyA = this.state.hotkeyMap[MilitaryBuildings[buildingA]];
      if (hotkeyA === undefined) continue;
      for (const buildingB in MilitaryBuildings) {
        if (buildingA === buildingB) continue;
        const hotkeyB = this.state.hotkeyMap[MilitaryBuildings[buildingB]];
        if (hotkeyA === hotkeyB) {
          conflicts.add(MilitaryBuildings[buildingA]);
        }
      }

      if (hotkeyA === this.state.hotkeyMap[NavigationActions.Cancel]) {
        conflicts.add(MilitaryBuildings[buildingA]);
        conflicts.add(NavigationActions.Cancel);
      }

      if (hotkeyA === this.state.hotkeyMap[NavigationActions.MoreBuildings]) {
        conflicts.add(MilitaryBuildings[buildingA]);
        conflicts.add(NavigationActions.MoreBuildings);
      }
    }

    // Compare Cancel vs MoreBuildings hotkeys
    const cancelHotkey = this.state.hotkeyMap[NavigationActions.Cancel];
    const moreHotkey = this.state.hotkeyMap[NavigationActions.MoreBuildings];
    if (cancelHotkey !== undefined && cancelHotkey === moreHotkey) {
      conflicts.add(NavigationActions.Cancel);
      conflicts.add(NavigationActions.MoreBuildings);
    }

    // Compare Economic Buildings vs Military Buildings hotkeys
    const ecoHotkey = this.state.hotkeyMap[NavigationActions.EconomicBuildings];
    const miliHotkey = this.state.hotkeyMap[NavigationActions.MilitaryBuildings];
    if (ecoHotkey === miliHotkey) {
      conflicts.add(NavigationActions.EconomicBuildings);
      conflicts.add(NavigationActions.MilitaryBuildings);
    }

    this.setState({ conflictingHotkeys: conflicts });
  }

  isHotkeyClearable(hotkeyName) {
    switch (hotkeyName) {
      case NavigationActions.EconomicBuildings:
      case NavigationActions.MilitaryBuildings:
        return false;
      default:
        return true;
    }
  }

  onProfileNameChanged(event) {
    const value = event.target.value;
    this.setState({ profileName: value === "" ? undefined : value });
  }

  onProfileNameKeypressed(event) {
    if (event.which === 32) {
      event.preventDefault();
    }
  }

  changeDefaultHotkeys(event) {
    const target = event.target;
    const value = target.value;

    if (value === "aoe-hd") {
      this.setState({ defaultHotkeys: hd_hotkeys });
    } else if (value === "aoe-de") {
      this.setState({ defaultHotkeys: de_hotkeys });
    } else if (value === "Hera") {
      this.setState({ defaultHotkeys: hera_hotkeys });
    } else if (value === "TheViper") {
      this.setState({ defaultHotkeys: viper_hotkeys });
    }
  }

  selectHotkey(event, hotkeyName) {
    const clickedElement = event.target;

    // De-select current selected hotkey
    const prevSelectedHotkeyElement = this.state.selectedHotkeyElement;
    this.setState({
      selectedHotkeyElement: undefined,
      selectedHotkeyName: undefined,
    });

    if (prevSelectedHotkeyElement) {
      prevSelectedHotkeyElement.style.color = null;
      prevSelectedHotkeyElement.style.backgroundColor = null;
    }

    // If re-clicking on selected hotkey, don't select it again
    if (prevSelectedHotkeyElement === clickedElement) {
      return;
    }

    // Select new hotkey
    this.setState(
      {
        selectedHotkeyElement: clickedElement,
        selectedHotkeyName: hotkeyName,
      },
      () => {
        // eslint-disable-next-line
        this.state.selectedHotkeyElement.style.color = "white";
        // eslint-disable-next-line
        this.state.selectedHotkeyElement.style.backgroundColor = "black";
      }
    );
  }

  render() {
    // If all hotkeys are cleared, display a warning message to the user
    let allBuildingHotkeysClearedWarning = undefined;
    // If hotkeyMap has over 4 keys, then we know that at least some buildings have hotkeys assigned
    if (Object.keys(this.state.hotkeyMap).length <= 4) {
      let areAnyBuildingHotkeysAssigned = false;
      for (const hotkey in this.state.hotkeyMap) {
        if (isEconomicBuilding(hotkey) || isMilitaryBuilding(hotkey)) {
          areAnyBuildingHotkeysAssigned = true;
        }
      }

      if (!areAnyBuildingHotkeysAssigned) {
        allBuildingHotkeysClearedWarning = <li>No buildings have assigned hotkeys</li>;
      }
    }

    // If hotkeys conflict, display a warning message to the user
    let hotkeyConflictsWarning = undefined;
    if (this.state.conflictingHotkeys.size > 0) {
      let buildingList = "Conflicting hotkeys for ";
      for (const name of this.state.conflictingHotkeys) {
        buildingList += `${name}, `;
      }

      // Remove comma and space from end
      buildingList = buildingList.slice(0, -2);
      hotkeyConflictsWarning = <li>{buildingList}</li>;
    }

    return (
      <div id="hotkey-editor">
        <h2>Hotkey Editor</h2>
        <CgClose id="close-icon" onClick={this.props.onHotkeyEditorClose} />
        <p class="hotkey-editor-instructions">
          To edit, just click on a hotkey and type the key(s) you want
        </p>

        {/* Hotkey Tables */}
        <HotkeyEditorNavigationTable
          hotkeyMap={this.state.hotkeyMap}
          selectionHandler={this.selectHotkey}
        />
        <HotkeyEditorBuildingTable
          hotkeyMap={this.state.hotkeyMap}
          selectionHandler={this.selectHotkey}
        />

        {allBuildingHotkeysClearedWarning || hotkeyConflictsWarning ? (
          <div class="hotkey-editor-warning">
            <p>WARNING: There are conflicts with your hotkey profile:</p>
            <ul>
              {hotkeyConflictsWarning}
              {allBuildingHotkeysClearedWarning}
            </ul>
          </div>
        ) : undefined}

        <div class="hotkey-editor-reset">
          <select defaultValue={"Select Default Hotkeys"} onChange={this.changeDefaultHotkeys}>
            <option disabled>Select Default Hotkeys</option>
            <option value="aoe-de">AoE II: Definitive Edition</option>
            <option value="aoe-hd">AoE II: HD Edition</option>
            <option value="Hera">Hera</option>
            <option value="TheViper">TheViper</option>
          </select>
          <button
            onClick={this.resetHotkeys}
            disabled={this.state.defaultHotkeys === undefined}
            title={this.state.defaultHotkeys === undefined ? "Select default hotkeys first" : ""}
          >
            Reset To Default
          </button>
          <button
            onClick={this.clearHotkey}
            disabled={
              this.state.selectedHotkeyName === undefined ||
              this.state.selectedHotkeyElement === undefined ||
              !this.isHotkeyClearable(this.state.selectedHotkeyName)
            }
            title={
              this.state.selectedHotkeyName === undefined ||
              this.state.selectedHotkeyElement === undefined
                ? "Select a hotkey to clear first"
                : ""
            }
          >
            Clear Hotkey
          </button>
        </div>

        <div class="hotkey-editor-save">
          <input
            id="profile-text-box"
            type="text"
            placeholder="Enter Hotkey Profile Name..."
            maxLength={35}
            onChange={this.onProfileNameChanged}
            onKeyPress={this.onProfileNameKeypressed}
            onPaste={(e) => e.preventDefault()}
          ></input>
          <button
            onClick={this.saveHotkeys}
            title="Make sure you enter a name profile name, and that there aren't any conflicts..."
            disabled={
              allBuildingHotkeysClearedWarning ||
              hotkeyConflictsWarning ||
              this.state.profileName === undefined
            }
          >
            Save
          </button>
        </div>
      </div>
    );
  }
}

export default HotkeyEditor;
