import React from "react";
import trainerResizer from "../utils/trainerResizer";
import { Helmet } from "react-helmet";
import "../css/shared.css";
import "../css/trainer.css";
import Settings from "./Settings";
import Map from "./Map";
import Results from "./Results";
import successSound from "../assets/sounds/success-sound.wav";
import errorSound from "../assets/sounds/error-sound.wav";
import NavBar from "./NavBar";
import HotkeyEditor from "./HotkeyEditor";
import { de_hotkeys } from "../hotkeys/villager-build/de";
import { hd_hotkeys } from "../hotkeys/villager-build/hd";
import { hera_hotkeys } from "../hotkeys/villager-build/hera";
import { viper_hotkeys } from "../hotkeys/villager-build/viper";
import { isEconomicBuilding } from "../utils/HotkeyNames";
import { PRE_LOADED_HOTKEY_SET_NAMES } from "../utils/constants";
import BuildingTextures from "../utils/BuildingTextures";

const TRAINER_TYPE = "VillagerTrainer";

class VillagerTrainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      training: false,
      hintsOn: true,
      buildingHint: undefined,
      resetNavHint: undefined,
      soundOn: true,
      hotkeySets: { "Age of Empires 2: Definitive Edition": de_hotkeys, "Age of Empires 2: HD Edition": hd_hotkeys, Hera: hera_hotkeys, TheViper: viper_hotkeys },
      hotkeySetSelected: undefined,
      buildingTarget: undefined,
      buildingEntered: undefined,
      treeCharStack: [],
      endOnClick: undefined,
      hpm: undefined,
      showingHotkeyEditor: false,
    };

    // Check if any hotkeys are saved in the cookies
    this.checkCookies();

    // Preload bassets
    this.preloadAssets();

    this.onKeyPress = this.onKeyPress.bind(this);
    this.handleTrainingButtonClick = this.handleTrainingButtonClick.bind(this);
    this.endTraining = this.endTraining.bind(this);
    this.handleSettingsChange = this.handleSettingsChange.bind(this);
    this.openHotkeyEditor = this.openHotkeyEditor.bind(this);
    this.closeHotkeyEditor = this.closeHotkeyEditor.bind(this);
    this.handleHotkeyEditorSave = this.handleHotkeyEditorSave.bind(this);

    window.addEventListener("resize", trainerResizer);
  }

  componentDidMount() {
    trainerResizer();
    document.addEventListener("keydown", this.onKeyPress);
  }

  componentWillUnmount() {
    document.removeEventListener("keydown", this.onKeyPress);
    window.removeEventListener("resize", trainerResizer);
  }

  preloadAssets() {
    // Preload building textures
    for (const imgName in BuildingTextures) {
      const img = new Image();
      img.src = BuildingTextures[imgName];
    }

    // Preload sound
    const s_audio = new Audio();
    const f_audio = new Audio();
    s_audio.src = successSound;
    f_audio.src = errorSound;
  }

  checkCookies() {
    // Get custom hotkey sets from cookie
    let hotkeySetCookie = document.cookie.split("; ").find((row) => row.startsWith("hotkeySets="));
    if (!hotkeySetCookie) return;
    hotkeySetCookie = hotkeySetCookie.split("=")[1].replace("@", ";");
    hotkeySetCookie = JSON.parse(hotkeySetCookie);

    // Save custom hotkey sets gotten from cookie
    for (const setName in hotkeySetCookie) {
      // eslint-disable-next-line
      this.state.hotkeySets[setName] = hotkeySetCookie[setName];
    }
  }

  startTraining() {
    this.setState({
      training: true,
      hpm: { matches: 0, fails: 0 },
      endOnClick: false,
    });
    this.fetchRandomHotkey();
  }

  endTraining() {
    this.setState({
      training: false,
      buildingTarget: undefined,
      buildingEntered: undefined,
      treeCharStack: [],
      buildingHint: undefined,
      resetNavHint: undefined,
    });
  }

  handleTrainingButtonClick() {
    if (this.state.hintsOn === undefined) {
      alert("You must specify whether you wants hints or not.");
    } else if (this.state.hotkeySetSelected === undefined) {
      alert("You must specify which hotkey set you want to train with.");
    } else {
      if (!this.state.training) {
        this.startTraining();
      } else {
        this.setState({ endOnClick: true });
        this.endTraining();
      }
    }
  }

  handleSettingsChange(event, changeType) {
    const target = event.target;
    switch (changeType) {
      case "hints":
        const value = target.value === "yes" ? true : false;
        this.setState({ hintsOn: value });
        break;
      case "game":
        this.setState({ hotkeySetSelected: target.value });
        break;
      case "sound":
        this.setState({ soundOn: !this.state.soundOn });
        break;
      default:
      //no-op
    }
  }

  openHotkeyEditor() {
    if (this.state.training) {
      this.setState({ endOnClick: true });
      this.endTraining();
    }

    window.removeEventListener("resize", trainerResizer);
    this.setState({ showingHotkeyEditor: true });
  }

  closeHotkeyEditor() {
    // Hide hotkey editor and show trainer
    window.addEventListener("resize", trainerResizer);
    this.setState({ showingHotkeyEditor: false }, () => {
      trainerResizer();
    });
  }

  handleHotkeyEditorSave(profileName, savedHotkeys) {
    // Create hotkey set out of saved hotkeys
    // eslint-disable-next-line
    this.state.hotkeySets[profileName] = savedHotkeys;

    // Get object of custom hotkey sets
    const customHotkeySets = {};
    for (const setName in this.state.hotkeySets) {
      if (PRE_LOADED_HOTKEY_SET_NAMES.indexOf(setName) === -1) {
        customHotkeySets[setName] = this.state.hotkeySets[setName];
      }
    }

    // Save custom hotkey sets in browser cookies (";" character not allowed in cookies)
    document.cookie = `hotkeySets=${JSON.stringify(customHotkeySets).replace(";", "@")}`;

    // Hide hotkey editor and show trainer
    window.addEventListener("resize", trainerResizer);
    this.setState({ showingHotkeyEditor: false, hotkeySetSelected: profileName }, () => {
      trainerResizer();
    });
  }

  fetchRandomHotkey() {
    // Choose random leaf from hotkey tree
    const hotkeySet = this.state.hotkeySets[this.state.hotkeySetSelected];
    const allBuildingsInTree = [];

    // Populate allBuildingsInTree list
    this.getHotkeyTreeLeaves(hotkeySet.tree, allBuildingsInTree);

    // Get random building from list
    const newBuildingTarget = allBuildingsInTree[(allBuildingsInTree.length * Math.random()) << 0];

    if (newBuildingTarget === this.state.buildingTarget && allBuildingsInTree.length > 1) {
      // If the name matches the previous hotkey target name, choose randomly again...
      // (only allow repeated building targets if hotkey tree only has one building)
      this.fetchRandomHotkey();
    } else {
      const buildingHint = this.state.hintsOn ? this.getHotkeys(newBuildingTarget) : undefined;
      this.setState({ buildingTarget: newBuildingTarget, buildingHint, resetNavHint: undefined });
    }
  }

  getHotkeyTreeLeaves(root, list) {
    if (typeof root === "string") {
      list.push(root);
      return;
    }

    for (const node in root) {
      const subtree = root[node];
      this.getHotkeyTreeLeaves(subtree, list);
    }
  }

  getHotkeys(buildingName) {
    let hotkeySet = undefined;
    if (this.state.hotkeySets[this.state.hotkeySetSelected]) {
      hotkeySet = this.state.hotkeySets[this.state.hotkeySetSelected].tree;
    }

    if (!hotkeySet) return undefined;

    for (const buildingTypeKey in hotkeySet) {
      for (const buildingKey in hotkeySet[buildingTypeKey]) {
        if (hotkeySet[buildingTypeKey][buildingKey] === buildingName) {
          return [buildingTypeKey, buildingKey];
        }
      }
    }

    return undefined;
  }

  isBuildingTargetInDestinationSubtree(hotkeySet, char, isESCKey) {
    if (this.state.treeCharStack.length === 0) {
      // User will remain at root (so the building target will be accessible)
      if (hotkeySet.tree[char] === undefined) return true;
      // Check if user will navigate to subtree that has building target
      const navigatingToEcoSubtree = hotkeySet.navigation[char] === "Economic Buildings";
      return navigatingToEcoSubtree === isEconomicBuilding(this.state.buildingTarget);
    }

    if (hotkeySet.navigation[char] === "More Buildings") {
      // Check if user will navigate to subtree that has building target
      const navigatingToEcoSubtree =
        hotkeySet.navigation[this.state.treeCharStack[0]] === "Military Buildings";
      return navigatingToEcoSubtree === isEconomicBuilding(this.state.buildingTarget);
    }

    if (hotkeySet.navigation[char] === "Cancel" || isESCKey) {
      // User will navigate to root (so the building target will be accessible)
      return true;
    }

    // User isn't navigating to new subtree, so check if building target is
    // accessible from current subtree
    const isInEcoSubtree =
      hotkeySet.navigation[this.state.treeCharStack[0]] === "Economic Buildings";
    return isInEcoSubtree === isEconomicBuilding(this.state.buildingTarget);
  }

  flashVisualEffect(success) {
    const effectColor = success ? "#56ff00" : "red";
    const map = document.getElementsByClassName("map")[0];
    map.style.outline = `10px solid ${effectColor}`;
    setTimeout(() => {
      map.style.outline = null;
    }, 150);
  }

  onKeyPress(e) {
    if (this.state.showingHotkeyEditor) return;

    // Prevents event from firing twice
    e.stopPropagation();
    e.preventDefault();

    // Exit function if training hasn't started
    if (!this.state.training) return;

    // Hash table of allowed non-alphanumeric character ascii codes
    const nonAlphaChars = {
      189: "-",
      187: "=",
      8: "Backspace",
      219: "[",
      221: "]",
      220: "\\",
      186: ";",
      222: "'",
      13: "Enter",
      188: ",",
      190: ".",
      191: "/",
      192: "`",
      9: "Tab",
      32: "Space",
      36: "Home",
      46: "Delete",
      37: "Left",
      38: "Up",
      39: "Right",
      40: "Down",
      33: "PgUp",
      34: "PgDown",
      27: "ESC",
    };

    let isCharValid = false;
    // Character in alphabet
    if (e.keyCode >= 65 && e.keyCode <= 90) isCharValid = true;
    // Character is numeric
    if (e.keyCode >= 48 && e.keyCode <= 57) isCharValid = true;
    // Character is allowed non-alphanumeric
    if (nonAlphaChars[e.keyCode] !== undefined) isCharValid = true;
    // Shift & Ctrl keys are being pressed simoultaneously
    if (e.ctrlKey && e.shiftKey) isCharValid = false;

    if (!isCharValid) return;

    // Get character from keycode
    var char =
      nonAlphaChars[e.keyCode] !== undefined
        ? nonAlphaChars[e.keyCode]
        : String.fromCharCode(e.keyCode);

    if (e.ctrlKey) char = "Ctrl + " + char;
    if (e.shiftKey) char = "Shift + " + char;

    const hotkeySet = this.state.hotkeySets[this.state.hotkeySetSelected];

    // Generate reset navigation hint if necessary
    if (
      this.isBuildingTargetInDestinationSubtree(hotkeySet, char, nonAlphaChars[e.keyCode] === "ESC")
    ) {
      this.setState({ resetNavHint: undefined });
    } else {
      let moreBuildingsKey;
      let cancelKey;
      for (const key in hotkeySet.navigation) {
        if (hotkeySet.navigation[key] === "More Buildings") {
          moreBuildingsKey = key;
        }

        if (hotkeySet.navigation[key] === "Cancel" || nonAlphaChars[e.keyCode] === "ESC") {
          cancelKey = key;
        }
      }

      this.setState({
        resetNavHint: {
          "More Buildings": moreBuildingsKey,
          Cancel: cancelKey,
        },
      });
    }

    // Process input as navigation attempt
    if (this.state.treeCharStack.length > 0) {
      if (hotkeySet.navigation[char] === "More Buildings") {
        if (hotkeySet.navigation[this.state.treeCharStack[0]] === "Economic Buildings") {
          for (const key in hotkeySet.navigation) {
            if (hotkeySet.navigation[key] === "Military Buildings") {
              this.setState({ treeCharStack: [key] });
            }
          }
        } else {
          for (const key in hotkeySet.navigation) {
            if (hotkeySet.navigation[key] === "Economic Buildings") {
              this.setState({ treeCharStack: [key] });
            }
          }
        }

        return;
      } else if (hotkeySet.navigation[char] === "Cancel" || nonAlphaChars[e.keyCode] === "ESC") {
        this.setState({ buildingEntered: undefined, treeCharStack: [] });
        return;
      }
    }

    // Process input as hotkey tree traversal attempt
    let invalidInput = false;
    let incorrectInput = false;
    const stack = [...this.state.treeCharStack];

    if (stack.length < 2) stack.push(char);
    else stack[1] = char;

    if (stack.length === 1) {
      // Check if leads to subtree
      if (hotkeySet.tree[stack[0]]) {
        this.setState({ treeCharStack: [...stack] });
      } else invalidInput = true;
    } else if (stack.length === 2) {
      // Check if input leads to leaf
      const leaf = hotkeySet.tree[stack[0]][stack[1]];
      if (leaf) {
        this.setState({ buildingEntered: leaf, treeCharStack: [...stack] }, () => {
          if (this.state.buildingTarget === leaf) {
            // Don't allow user to enter more keys before next hotkey is loaded
            document.removeEventListener("keydown", this.onKeyPress);
            // Play sucess sound effect
            if (this.state.soundOn) {
              let audio = new Audio(successSound);
              audio.play();
            }
            // Record successful match
            // eslint-disable-next-line
            this.state.hpm["matches"] += 1;
            // Flash success visual effect
            this.flashVisualEffect(true /* success */);

            setTimeout(() => {
              this.setState({ buildingEntered: undefined, treeCharStack: [] }, () => {
                this.fetchRandomHotkey();
                // Hotkey is loaded, so allow user to enter keys
                document.addEventListener("keydown", this.onKeyPress);
              });
            }, 500);
          } else incorrectInput = true;
        });
      } else invalidInput = true;
    }

    if (invalidInput) stack.pop();
    if (invalidInput || incorrectInput) {
      // Play error sound effect
      if (this.state.soundOn) {
        let audio = new Audio(errorSound);
        audio.play();
      }
      // Record incorrect input
      // eslint-disable-next-line
      this.state.hpm["fails"] += 1;
      // Flash success visual effect
      this.flashVisualEffect(false /* success */);
    }
  }

  render() {
    const settings = (
      <Settings
        trainerType={TRAINER_TYPE}
        training={this.state.training}
        soundOn={this.state.soundOn}
        hotkeySets={Object.keys(this.state.hotkeySets)}
        hotkeySetSelected={this.state.hotkeySetSelected}
        hintsOn={this.state.hintsOn}
        handleTrainingButtonClick={this.handleTrainingButtonClick}
        handleSettingsChange={this.handleSettingsChange}
        handleHotkeyEditorClick={this.openHotkeyEditor}
      />
    );

    const map = (
      <Map
        trainerType={TRAINER_TYPE}
        training={this.state.training}
        handleTrainingEnd={this.endTraining}
        buildingTarget={this.state.buildingTarget}
        buildingEntered={this.state.buildingEntered}
        enteredHotkey={this.state.treeCharStack}
        hintsOn={this.state.hintsOn}
        buildingHint={this.state.buildingHint}
        resetNavHint={this.state.resetNavHint}
      />
    );

    const trainer = (
      <div class="trainer-container">
        <div class="title-row">
          <h1 class="trainer-title">Villager Hotkey Trainer</h1>
          <div class="results-container">
            <Results
              showResults={!this.state.training && this.state.endOnClick === false}
              hpm={this.state.hpm}
            />
          </div>
        </div>
        {settings}
        {map}
      </div>
    );

    const hotkeyEditor = (
      <HotkeyEditor
        onHotkeyEditorSave={this.handleHotkeyEditorSave}
        onHotkeyEditorClose={this.closeHotkeyEditor}
      />
    );

    return (
      <div class="page-container">
        {/* HTML Meta tags */}
        <Helmet>
          <title>aoehippo | Villager Hotkey Trainer</title>
          <meta name="description" content="" />
        </Helmet>

        {/* Naviation bar at the top of the page */}
        <NavBar />

        {/* Main content of the page */}
        <div class="trainer main-content">
          {!this.state.showingHotkeyEditor ? trainer : hotkeyEditor}
          {/* Message to display if screen size is too small to fit trainer */}
          <p id="screen-too-small">
            Sorry, your device's screen is too small to display our hotkey trainer correctly (either
            increase window size, put device on landscape orientation, or use different device).
          </p>
        </div>

        {/* Footer */}
        <footer class="main-footer">
          <h3>--- aoehippo ---</h3>
        </footer>
      </div>
    );
  }
}

export default VillagerTrainer;
