import React from "react";
import "../css/shared.css";
import "../css/hotkey-editor.css";
import HotkeyEditorTableRow from "./HotkeyEditorTableRow";
import { EconomicBuildings, MilitaryBuildings } from "../utils/HotkeyNames";

class HotkeyEditorBuildingTable extends React.Component {
  render() {
    return (
      <table class="hotkey-editor-table">
        <td>
          <HotkeyEditorTableRow
            name={MilitaryBuildings.ArcheryRange}
            hotkey={this.props.hotkeyMap[MilitaryBuildings.ArcheryRange]}
            clickHandler={this.props.selectionHandler}
          />
          <HotkeyEditorTableRow
            name={MilitaryBuildings.Barracks}
            hotkey={this.props.hotkeyMap[MilitaryBuildings.Barracks]}
            clickHandler={this.props.selectionHandler}
          />
          <HotkeyEditorTableRow
            name={EconomicBuildings.Blacksmith}
            hotkey={this.props.hotkeyMap[EconomicBuildings.Blacksmith]}
            clickHandler={this.props.selectionHandler}
          />
          <HotkeyEditorTableRow
            name={MilitaryBuildings.Castle}
            hotkey={this.props.hotkeyMap[MilitaryBuildings.Castle]}
            clickHandler={this.props.selectionHandler}
          />
          <HotkeyEditorTableRow
            name={EconomicBuildings.Dock}
            hotkey={this.props.hotkeyMap[EconomicBuildings.Dock]}
            clickHandler={this.props.selectionHandler}
          />
          <HotkeyEditorTableRow
            name={EconomicBuildings.Farm}
            hotkey={this.props.hotkeyMap[EconomicBuildings.Farm]}
            clickHandler={this.props.selectionHandler}
          />
          <HotkeyEditorTableRow
            name={MilitaryBuildings.Gate}
            hotkey={this.props.hotkeyMap[MilitaryBuildings.Gate]}
            clickHandler={this.props.selectionHandler}
          />
          <HotkeyEditorTableRow
            name={EconomicBuildings.House}
            hotkey={this.props.hotkeyMap[EconomicBuildings.House]}
            clickHandler={this.props.selectionHandler}
          />
          <HotkeyEditorTableRow
            name={EconomicBuildings.LumberCamp}
            hotkey={this.props.hotkeyMap[EconomicBuildings.LumberCamp]}
            clickHandler={this.props.selectionHandler}
          />
          <HotkeyEditorTableRow
            name={EconomicBuildings.Market}
            hotkey={this.props.hotkeyMap[EconomicBuildings.Market]}
            clickHandler={this.props.selectionHandler}
          />
          <HotkeyEditorTableRow
            name={EconomicBuildings.Mill}
            hotkey={this.props.hotkeyMap[EconomicBuildings.Mill]}
            clickHandler={this.props.selectionHandler}
          />
        </td>
        <td>
          <HotkeyEditorTableRow
            name={EconomicBuildings.MiningCamp}
            hotkey={this.props.hotkeyMap[EconomicBuildings.MiningCamp]}
            clickHandler={this.props.selectionHandler}
          />
          <HotkeyEditorTableRow
            name={EconomicBuildings.Monastery}
            hotkey={this.props.hotkeyMap[EconomicBuildings.Monastery]}
            clickHandler={this.props.selectionHandler}
          />
          <HotkeyEditorTableRow
            name={MilitaryBuildings.Outpost}
            hotkey={this.props.hotkeyMap[MilitaryBuildings.Outpost]}
            clickHandler={this.props.selectionHandler}
          />
          <HotkeyEditorTableRow
            name={MilitaryBuildings.PalisadeGate}
            hotkey={this.props.hotkeyMap[MilitaryBuildings.PalisadeGate]}
            clickHandler={this.props.selectionHandler}
          />
          <HotkeyEditorTableRow
            name={MilitaryBuildings.PalisadeWall}
            hotkey={this.props.hotkeyMap[MilitaryBuildings.PalisadeWall]}
            clickHandler={this.props.selectionHandler}
          />
          <HotkeyEditorTableRow
            name={MilitaryBuildings.SiegeWorkshop}
            hotkey={this.props.hotkeyMap[MilitaryBuildings.SiegeWorkshop]}
            clickHandler={this.props.selectionHandler}
          />
          <HotkeyEditorTableRow
            name={MilitaryBuildings.Stable}
            hotkey={this.props.hotkeyMap[MilitaryBuildings.Stable]}
            clickHandler={this.props.selectionHandler}
          />
          <HotkeyEditorTableRow
            name={MilitaryBuildings.StoneWall}
            hotkey={this.props.hotkeyMap[MilitaryBuildings.StoneWall]}
            clickHandler={this.props.selectionHandler}
          />
          <HotkeyEditorTableRow
            name={EconomicBuildings.TownCenter}
            hotkey={this.props.hotkeyMap[EconomicBuildings.TownCenter]}
            clickHandler={this.props.selectionHandler}
          />
          <HotkeyEditorTableRow
            name={EconomicBuildings.University}
            hotkey={this.props.hotkeyMap[EconomicBuildings.University]}
            clickHandler={this.props.selectionHandler}
          />
          {/* Empty Row */}
          <tr style={{ cursor: "default" }}>
            <p class="hotkey-name" style={{ visibility: "hidden" }}>
              .
            </p>
            <p class="hotkey"></p>
          </tr>
        </td>
      </table>
    );
  }
}

export default HotkeyEditorBuildingTable;
