import townCenter from "../assets/icons/town-center.png";
import house from "../assets/icons/house.png";
import mill from "../assets/icons/mill.png";
import lumberCamp from "../assets/icons/lumber-camp.png";
import miningCamp from "../assets/icons/mining-camp.png";
import dock from "../assets/icons/dock.png";
import farm from "../assets/icons/farm.png";
import market from "../assets/icons/market.png";
import blacksmith from "../assets/icons/blacksmith.png";
import barracks from "../assets/icons/barracks.png";
import archeryRange from "../assets/icons/archery-range.png";
import stable from "../assets/icons/stable.png";
import siegeWorkshop from "../assets/icons/siege-workshop.png";
import outpost from "../assets/icons/outpost.png";
import palisadeWall from "../assets/icons/palisade-wall.png";
import stoneWall from "../assets/icons/stone-wall.png";
import gate from "../assets/icons/gate.png";
import palisadeGate from "../assets/icons/palisade-gate.png";
import castle from "../assets/icons/castle.png";
import monastery from "../assets/icons/monastery.png";
import university from "../assets/icons/university.png";

const BuildingIcons = {
  Blacksmith: blacksmith,
  Dock: dock,
  Farm: farm,
  House: house,
  "Lumber Camp": lumberCamp,
  Market: market,
  Mill: mill,
  "Mining Camp": miningCamp,
  Monastery: monastery,
  "Town Center": townCenter,
  University: university,
  "Archery Range": archeryRange,
  Barracks: barracks,
  Castle: castle,
  Gate: gate,
  Outpost: outpost,
  "Palisade Gate": palisadeGate,
  "Palisade Wall": palisadeWall,
  "Siege Workshop": siegeWorkshop,
  Stable: stable,
  "Stone Wall": stoneWall,
};

export default BuildingIcons;
