import React from "react";
import "../css/shared.css";
import "../css/hotkey-editor.css";
import { NavigationActions } from "../utils/HotkeyNames";

class HotkeyEditorTableRow extends React.Component {
  render() {
    let hotkey = this.props.hotkey || "???";
    if (this.props.name === NavigationActions.Cancel) {
      hotkey += " / ESC";
    }
    return (
      <tr onClick={(event) => this.props.clickHandler(event, this.props.name)}>
        <p class="hotkey-name">{this.props.name}</p>
        <p class="hotkey">{hotkey}</p>
      </tr>
    );
  }
}

export default HotkeyEditorTableRow;
