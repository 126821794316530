export const EconomicBuildings = {
  Blacksmith: "Blacksmith",
  Dock: "Dock",
  Farm: "Farm",
  House: "House",
  LumberCamp: "Lumber Camp",
  Market: "Market",
  Mill: "Mill",
  MiningCamp: "Mining Camp",
  Monastery: "Monastery",
  TownCenter: "Town Center",
  University: "University",
};

export const MilitaryBuildings = {
  ArcheryRange: "Archery Range",
  Barracks: "Barracks",
  Castle: "Castle",
  Gate: "Gate",
  Outpost: "Outpost",
  PalisadeGate: "Palisade Gate",
  PalisadeWall: "Palisade Wall",
  SiegeWorkshop: "Siege Workshop",
  Stable: "Stable",
  StoneWall: "Stone Wall",
};

export const NavigationActions = {
  EconomicBuildings: "Economic Buildings",
  MilitaryBuildings: "Military Buildings",
  MoreBuildings: "More Buildings",
  Cancel: "Cancel",
};

const EconomicBuildingsSet = new Set(Object.values(EconomicBuildings));
const MilitaryBuildingsSet = new Set(Object.values(MilitaryBuildings));
const NavigationActionsSet = new Set(Object.values(NavigationActions));

export const isEconomicBuilding = (key) => {
  return EconomicBuildingsSet.has(key);
};

export const isMilitaryBuilding = (key) => {
  return MilitaryBuildingsSet.has(key);
};

export const isNavigationAction = (key) => {
  return NavigationActionsSet.has(key);
};
