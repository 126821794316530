import React from "react";
import { GiHamburgerMenu } from "react-icons/gi";

class NavBar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      hamburgerMenuOpen: false,
    };

    this.toggleHamburgerMenu = this.toggleHamburgerMenu.bind(this);
  }

  toggleHamburgerMenu() {
    console.log("bruh");
    const hamburgerMenu = document.getElementsByClassName("hamburger-nav")[0];
    const removableMainNavItems = document.getElementsByClassName("remove-nav-item");
    const navBar = document.getElementsByClassName("main-header")[0];
    const mainContent = document.getElementsByClassName("main-content")[0];
    if (this.state.hamburgerMenuOpen) {
      navBar.style.position = "fixed";
      mainContent.style.marginTop = "60px";
      hamburgerMenu.style.display = "none";
      for (const navItem of removableMainNavItems) {
        navItem.style.display = "inline-block";
      }
    } else {
      navBar.style.position = "relative";
      mainContent.style.marginTop = "0px";
      hamburgerMenu.style.display = "block";
      for (const navItem of removableMainNavItems) {
        navItem.style.display = "none";
      }
    }

    this.setState({ hamburgerMenuOpen: !this.state.hamburgerMenuOpen });
  }

  render() {
    return (
      <header class="main-header">
        <nav class="main-nav">
          <ul class="main-nav__items">
            <li class="main-nav__item">
              <a href="/">aoehippo</a>
            </li>
            <li class="main-nav__item remove-nav-item">
              <a href="/hotkey-trainer">Trainer</a>
            </li>
            <li class="main-nav__item remove-nav-item">
              <a href="/feedback">Feedback</a>
            </li>
            <li class="main-nav__item remove-nav-item">
              <a href="https://donate.stripe.com/3cs5mRcBeejl5t6144">Donate</a>
            </li>
            <li class="main-nav__item remove-nav-item">
              <a href="/disclaimer">Disclaimer</a>
            </li>
          </ul>
        </nav>
        <div class="hamburger-icon-container" onClick={this.toggleHamburgerMenu}>
          <div class="icon-wrapper">
            <GiHamburgerMenu id="hamburger-icon" />
          </div>
        </div>
        <nav class="hamburger-nav">
          <ul class="hamburger-nav__items">
            <li class="hamburger-nav__item">
              <a href="/hotkey-trainer">Trainer</a>
            </li>
            <li class="hamburger-nav__item">
              <a href="/feedback">Feedback</a>
            </li>
            <li class="hamburger-nav__item">
              <a href="https://donate.stripe.com/3cs5mRcBeejl5t6144">Donate</a>
            </li>
            <li class="hamburger-nav__item">
              <a href="/disclaimer">Disclaimer</a>
            </li>
          </ul>
        </nav>
      </header>
    );
  }
}

export default NavBar;
