const tree = {
  B: {
    E: "Blacksmith",
    ";": "Dock",
    F: "Farm",
    G: "House",
    N: "Lumber Camp",
    T: "Market",
    V: "Mill",
    M: "Mining Camp",
    Y: "Monastery",
    H: "Town Center",
    R: "University",
  },
  K: {
    K: "Archery Range",
    J: "Barracks",
    O: "Castle",
    P: "Outpost",
    C: "Palisade Gate",
    B: "Palisade Wall",
    U: "Siege Workshop",
    L: "Stable",
  },
};

const navigation = {
  B: "Economic Buildings",
  K: "Military Buildings",
};

export const hera_hotkeys = {
  tree,
  navigation,
};
