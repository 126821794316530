const tree = {
  A: {
    S: "Blacksmith",
    T: "Dock",
    A: "Farm",
    Q: "House",
    R: "Lumber Camp",
    D: "Market",
    W: "Mill",
    E: "Mining Camp",
    F: "Monastery",
    Z: "Town Center",
    G: "University",
  },
  S: {
    W: "Archery Range",
    Q: "Barracks",
    C: "Castle",
    Z: "Gate",
    A: "Outpost",
    X: "Palisade Gate",
    S: "Palisade Wall",
    R: "Siege Workshop",
    E: "Stable",
    D: "Stone Wall",
  },
};

const navigation = {
  A: "Economic Buildings",
  S: "Military Buildings",
  V: "More Buildings",
  B: "Cancel",
};

export const hd_hotkeys = {
  tree,
  navigation,
};
