import townCenter from "../assets/textures/town-center";
import house from "../assets/textures/house";
import mill from "../assets/textures/mill";
import lumberCamp from "../assets/textures/lumber-camp";
import miningCamp from "../assets/textures/mining-camp";
import dock from "../assets/textures/dock";
import farm from "../assets/textures/farm";
import market from "../assets/textures/market";
import blacksmith from "../assets/textures/blacksmith";
import barracks from "../assets/textures/barracks";
import archeryRange from "../assets/textures/archery-range";
import stable from "../assets/textures/stable";
import siegeWorkshop from "../assets/textures/siege-workshop";
import outpost from "../assets/textures/outpost";
import palisadeWall from "../assets/textures/palisade-wall";
import stoneWall from "../assets/textures/wall";
import gate from "../assets/textures/stone-gate";
import palisadeGate from "../assets/textures/palisade-gate";
import castle from "../assets/textures/castle";
import monastery from "../assets/textures/monastery";
import university from "../assets/textures/university";

const BuildingTextures = {
  Blacksmith: blacksmith,
  Dock: dock,
  Farm: farm,
  House: house,
  "Lumber Camp": lumberCamp,
  Market: market,
  Mill: mill,
  "Mining Camp": miningCamp,
  Monastery: monastery,
  "Town Center": townCenter,
  University: university,
  "Archery Range": archeryRange,
  Barracks: barracks,
  Castle: castle,
  Gate: gate,
  Outpost: outpost,
  "Palisade Gate": palisadeGate,
  "Palisade Wall": palisadeWall,
  "Siege Workshop": siegeWorkshop,
  Stable: stable,
  "Stone Wall": stoneWall,
};

export default BuildingTextures;
