function trainerResizer() {
  // Get a reference to the html elements we want to resize
  const titleRow = document.getElementsByClassName("title-row")[0];
  const map = document.getElementsByClassName("map")[0];
  const settings = document.getElementsByClassName("settings")[0];

  // Get the style of a couple of html elements (we will need this to calculate the map's dimensions)
  const mainContentDivStyle = window.getComputedStyle(
    document.getElementsByClassName("trainer")[0]
  );
  const trainerContainerStyle = window.getComputedStyle(
    document.getElementsByClassName("trainer-container")[0]
  );
  const settingsStyle = window.getComputedStyle(document.getElementsByClassName("settings")[0]);

  // Calculate the width and height of the space we have to fit the map
  const availableWidth =
    parseFloat(trainerContainerStyle.width) - parseFloat(settingsStyle.width) - 30;
  const availableHeight =
    parseFloat(mainContentDivStyle.height) -
    parseFloat(mainContentDivStyle.paddingTop) -
    parseFloat(mainContentDivStyle.paddingBottom) -
    70;

  // Resize trainer-container so that the map always maintain 4:3 aspect ratio
  if (availableWidth * 0.75 > availableHeight) {
    map.style.width = `${availableHeight / 0.75}px`;
    map.style.height = `${availableHeight}px`;
    // Keep the settings panel's height equal to the map's
    settings.style.height = `${availableHeight}px`;
    // Position results over map and settings
    titleRow.style.width = `${availableHeight / 0.75 + parseFloat(settingsStyle.width) + 30}px`;
  } else {
    map.style.width = `${availableWidth}px`;
    map.style.height = `${availableWidth * 0.75}px`;
    // Keep the settings panel's height equal to the map's
    settings.style.height = `${availableWidth * 0.75}px`;
    // Position results over map and settings
    titleRow.style.width = `${availableWidth + parseFloat(settingsStyle.width) + 30}px`;
  }
}

export default trainerResizer;
