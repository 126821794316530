const tree = {
  B: {
    S: "Blacksmith",
    D: "Dock",
    F: "Farm",
    E: "House",
    Z: "Lumber Camp",
    M: "Market",
    I: "Mill",
    G: "Mining Camp",
    Y: "Monastery",
    N: "Town Center",
    U: "University",
  },
  V: {
    A: "Archery Range",
    B: "Barracks",
    V: "Castle",
    X: "Gate",
    O: "Outpost",
    Z: "Palisade Gate",
    C: "Palisade Wall",
    K: "Siege Workshop",
    L: "Stable",
  },
};

const navigation = {
  B: "Economic Buildings",
  V: "Military Buildings",
};

export const viper_hotkeys = {
  tree,
  navigation,
};
