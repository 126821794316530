import React from "react";
import Logo from "./Logo";
import "../css/shared.css";
import "../css/home.css";
import bg_image from "../assets/age-of-empires-2-de-burgundians.jpeg";
import hot_emoji from "../assets/hot-emoji.png";
import flushed_emoji from "../assets/flushed-emoji.png";
import NavBar from "./NavBar";

class Home extends React.Component {
  render() {
    return (
      <div class="page-container">
        <NavBar />

        <div class="home main-content">
          <div class="intro">
            <div class="intro-content">
              <div class="intro-content__text">
                <Logo />
                <h3>
                  Welcome to aoehippo! This website has been created for Age of Empires 2 players
                  who want to become better at using the in-game hotkeys. Using hotkeys allows
                  players to become faster at the game, and therefore accomplish more in a given set
                  of time.
                </h3>
                <h3>Take your game to the next level. Try our hotkey trainer for AoE 2!</h3>
                <a href="/villager-trainer">
                  <button>Start Hotkey Training</button>
                </a>
              </div>
            </div>
          </div>
          <div class="info">
            <h3>Build this in 2 seconds using hotkeys!</h3>
            <div class="emoji-container">
              <img class="emoji" src={flushed_emoji} alt="Flushed Face emoji." />
              <img class="emoji" src={hot_emoji} alt="Hot Face emoji." />
            </div>
            <img
              class="hotkey-town"
              src={bg_image}
              alt="Age of Empires 2 city built with hotkeys."
            />
          </div>
        </div>

        <footer class="main-footer">
          <h3>@ 2022 aoehippo</h3>
        </footer>
      </div>
    );
  }
}

export default Home;
