import React from "react";
import Home from "./components/Home";
import Trainer from "./components/Trainer";
import Feedback from "./components/Feedback";
// import Donate from "./components/Donate";
import Disclaimer from "./components/Disclaimer";
import { BrowserRouter, Routes, Route } from "react-router-dom";
// import JumpToTrainer from "./components/JumpToTrainer";
import VillagerTrainer from "./components/VillagerTrainer";

class App extends React.Component {
  render() {
    return (
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/hotkey-trainer" element={<Trainer />} />
          <Route path="/feedback" element={<Feedback />} />
          {/* <Route path="/donate" element={<Donate />} /> */}
          <Route path="/disclaimer" element={<Disclaimer />} />
          <Route path="/villager-trainer" element={<VillagerTrainer />} />
          {/* <Route path="/building-selection-trainer" element={<JumpToTrainer />} /> */}
        </Routes>
      </BrowserRouter>
    );
  }
}

export default App;
