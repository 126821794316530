import React from "react";

const TRAINING_TIME = 60;

class Timer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      timerVal: 0,
    };

    this.startTimer = this.startTimer.bind(this);
    this.endTimer = this.endTimer.bind(this);
  }

  startTimer() {
    this.setState({ timerVal: TRAINING_TIME });
    // Make timer val decrease by one every 1000ms
    this.myInterval = setInterval(() => {
      if (this.state.timerVal === 0) {
        this.endTimer();
      } else {
        this.setState({ timerVal: this.state.timerVal - 1 });
      }
    }, 1000);
  }

  endTimer() {
    clearInterval(this.myInterval);
    this.setState({ timerVal: 0 });
    // Only call training end handler if timer isn't ending
    // because of a prop change
    if (this.props.timerOn) {
      this.props.handleEnd();
    }
  }

  componentDidUpdate(prevProps) {
    // If there was a prop change, it means that we are starting/ending timer
    if (prevProps.timerOn !== this.props.timerOn) {
      if (this.props.timerOn) {
        this.startTimer();
      } else {
        this.endTimer();
      }
    }
  }

  componentWillUnmount() {
    clearInterval(this.myInterval);
  }

  formatTime(val) {
    if (val === 60) {
      return "1:00s";
    } else if (val >= 10) {
      return "0:" + val.toString() + "s";
    } else {
      return "0:0" + val.toString() + "s";
    }
  }

  render() {
    return (
      <div class="timer">
        <div class="icon-wrapper">
          <h1>{this.formatTime(this.state.timerVal)}</h1>
        </div>
      </div>
    );
  }
}

export default Timer;
