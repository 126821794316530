import React from "react";
import { HiVolumeUp, HiVolumeOff } from "react-icons/hi";

class Settings extends React.Component {
  componentDidUpdate(prevProps) {
    if (prevProps.training !== this.props.training) {
      this.styleTrainingButton();
    }
  }

  styleTrainingButton() {
    const button = document.getElementsByClassName("training-button")[0];
    if (this.props.training) {
      button.style.backgroundColor = "red";
    } else {
      button.style.backgroundColor = "#827717";
    }
  }

  render() {
    let gameSelector;
    let instructions;
    let reminder;
    if (this.props.trainerType === "VillagerTrainer") {
      const options = this.props.hotkeySets.map((hotkeySetName) => {
        return <option value={hotkeySetName}>{hotkeySetName}</option>;
      });

      gameSelector = (
        <div class="game-menu">
          <select
            defaultValue={this.props.hotkeySetSelected || "Select Hotkey Set"}
            disabled={this.props.training}
            onChange={(event) => this.props.handleSettingsChange(event, "game")}
          >
            <option disabled>Select Hotkey Set</option>
            {options}
          </select>
          <p onClick={this.props.handleHotkeyEditorClick}> ...or enter your custom hotkeys!</p>
        </div>
      );
      instructions =
        "Villager hotkeys are used to tell villagers build stuff. To train, press the start button and type the hotkeys for the building icon displayed. Type as many correct hotkeys as you can before the timer is done!";
    } else {
      instructions =
        'Building selection hotkeys are used to quickly "jump to" buildings on the map. To train, press the start button and type the hotkeys for the building icon displayed.';
      reminder = (
        <p>
          (REMEMBER: Type <strong class="shift">SHIFT</strong> instead of <strong>CTRL</strong>)
        </p>
      );
    }

    return (
      <div class="settings">
        <h2>Instructions</h2>
        <p>{instructions}</p>
        {reminder}
        <h2>Settings</h2>
        {gameSelector}
        <div className="hints-menu">
          <p>Hints:</p>
          <select
            defaultValue={this.props.hintsOn ? "yes" : "no"}
            disabled={this.props.training}
            onChange={(event) => this.props.handleSettingsChange(event, "hints")}
          >
            <option value="yes">Yes</option>
            <option value="no">No</option>
          </select>
        </div>
        <div className="sound-menu">
          <p>Sound Effects:</p>
          <div
            class="sound-icon-container"
            onClick={(event) => this.props.handleSettingsChange(event, "sound")}
          >
            {this.props.soundOn ? (
              <HiVolumeUp id="sound-on-icon" />
            ) : (
              <HiVolumeOff id="sound-off-icon" />
            )}
          </div>
        </div>

        <button
          class="training-button shrink-animation"
          onClick={this.props.handleTrainingButtonClick}
        >
          {this.props.training ? "End" : "Start"} Training
        </button>
      </div>
    );
  }
}

export default Settings;
