import React from "react";
import BuildingPrompt from "./BuildingPrompt";
import Timer from "./Timer";
import BuildingIncons from "../utils/BuildingIcons";
import BuildingTextures from "../utils/BuildingTextures";
import { isEconomicBuilding } from "../utils/HotkeyNames";
import "../css/map.css";

class Map extends React.Component {
  render() {
    if (this.props.trainerType === "VillagerTrainer") {
      // Get building texture
      let buildingTexture;
      if (BuildingTextures[this.props.buildingEntered]) {
        let className = "villager-trainer-map_building";
        if (this.props.buildingEntered !== this.props.buildingTarget) {
          className += " villager-trainer-map_building_gray";
        }

        buildingTexture = (
          <img
            class={className}
            src={BuildingTextures[this.props.buildingEntered]}
            alt={"AOE Building assigned to typed hotkey."}
          />
        );
      }

      let hint;
      if (this.props.hintsOn && this.props.buildingTarget) {
        if (this.props.resetNavHint) {
          const moreBuildingsHint = this.props.resetNavHint["More Buildings"];
          const cancelBuildingsHint = this.props.resetNavHint["Cancel"];

          let hintText;
          if (moreBuildingsHint !== undefined) {
            hintText = (
              <p>
                Press <strong>{moreBuildingsHint}</strong> for More Buildings, or press{" "}
                <strong>{cancelBuildingsHint}</strong> {cancelBuildingsHint ? "/" : ""}{" "}
                <strong>Esc</strong> to Cancel
              </p>
            );
          } else {
            hintText = (
              <p>
                Press <strong>{cancelBuildingsHint}</strong> {cancelBuildingsHint ? "/" : ""}{" "}
                <strong>Esc</strong> to Cancel
              </p>
            );
          }

          hint = <div class="hint">{hintText}</div>;
        } else if (this.props.buildingHint) {
          hint = (
            <div class="hint">
              <p>
                Press <strong>{this.props.buildingHint[0]}</strong> for{" "}
                {isEconomicBuilding(this.props.buildingTarget) ? "Economic" : "Military"} Buildings{" "}
                {"-->"} Press <strong>{this.props.buildingHint[1]}</strong> for{" "}
                {this.props.buildingTarget}
              </p>
            </div>
          );
        }
      }

      return (
        <div class="map villager-trainer-map">
          <BuildingPrompt
            hintsOn={this.props.hintsOn && this.props.training}
            icon={BuildingIncons[this.props.buildingTarget]}
            userInput={this.props.enteredHotkey}
            trainerType={this.props.trainerType}
          />
          <Timer timerOn={this.props.training} handleEnd={this.props.handleTrainingEnd} />
          {buildingTexture}
          {hint}
        </div>
      );
    } else if (this.props.trainerType === "BuildingSelectionTrainer") {
      console.log(this.props.building);
      return (
        <div
          class="map building-selection-trainer-map"
          style={{ backgroundPosition: this.props.coordinates }}
        >
          <BuildingPrompt
            hintsOn={this.props.hintsOn && this.props.training}
            hint={this.props.hotkey}
            building={this.props.building}
            userInput={this.props.enteredHotkey}
          />
          <Timer timerOn={this.props.training} handleEnd={this.props.handleTrainingEnd} />
          <div
            class="building-selection-trainer-map selection-box"
            style={{
              backgroundPosition: this.props.coordinates,
              backgroundImage: `url(${this.props.selectionBox})`,
            }}
          ></div>
        </div>
      );
    }
  }
}

export default Map;
