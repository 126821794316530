import React from "react";
import "../css/shared.css";
import "../css/logo.css";

class Logo extends React.Component {
  render() {
    return (
      <div class="logo-component">
        <h1>aoe</h1>
        <h1>hippo</h1>
      </div>
    );
  }
}

export default Logo;
