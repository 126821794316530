import React from "react";
import { Helmet } from "react-helmet";
import "../css/feedback.css";
import "../css/shared.css";
import NavBar from "./NavBar";

class Feedback extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      fname: null,
      lname: null,
      rating: null,
      comment: null,
      formResponse: null,
      formSubmitted: false,
    };

    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleInputChange(event) {
    // Get of form element that has changed
    const target = event.target;
    const value = target.value;
    const name = target.name;

    // Update the state with new form value
    this.setState({
      [name]: value,
    });
  }

  handleSubmit() {
    // Change state so that we render response page
    this.setState({ formSubmitted: true });

    // Send form data to endpoint
    window
      .fetch("../../../../api/submit-feedback", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          fname: this.state.fname !== null ? this.state.fname : "null",
          lname: this.state.lname !== null ? this.state.lname : "null",
          rating: this.state.rating !== null ? this.state.rating : "null",
          comment: this.state.comment !== null ? this.state.comment : "null",
        }),
      })
      // The request went through
      .then((res) => {
        return res.json();
      })
      .then((data) => {
        // Save the response request response
        this.setState({
          formResponse:
            data.message === "Feedback was submitted successfully."
              ? data.message
              : "There was a problem submitting this form, try again later.",
        });
      });
  }

  render() {
    const shouldDisplayForm = !this.state.formSubmitted;
    const feedbackForm = (
      <form class="feedback-form" onSubmit={this.handleSubmit}>
        <h2>Rate Your Experience</h2>
        <input
          type="text"
          id="fname"
          name="fname"
          placeholder="First Name"
          required
          maxLength="40"
          onChange={this.handleInputChange}
        ></input>
        <input
          type="text"
          id="lname"
          name="lname"
          placeholder="Last Name"
          maxLength="40"
          onChange={this.handleInputChange}
        ></input>
        <div class="ratings">
          <label>
            <input
              type="radio"
              class="rating"
              name="rating"
              value="Amazing"
              onChange={this.handleInputChange}
              required
            />{" "}
            Amazing
          </label>
          <label>
            <input
              type="radio"
              class="rating"
              name="rating"
              value="Good"
              onChange={this.handleInputChange}
              required
            />{" "}
            Good
          </label>
          <label>
            <input
              type="radio"
              class="rating"
              name="rating"
              value="Okay"
              onChange={this.handleInputChange}
              required
            />{" "}
            Okay
          </label>
          <label>
            <input
              type="radio"
              class="rating"
              name="rating"
              value="Poor"
              onChange={this.handleInputChange}
              required
            />{" "}
            Poor
          </label>
          <label>
            <input
              type="radio"
              class="rating"
              name="rating"
              value="Terrible"
              onChange={this.handleInputChange}
              required
            />{" "}
            Terrible
          </label>
        </div>
        <p>You have 500/500 characters left.</p>
        <textarea
          id="comment"
          name="comment"
          rows="4"
          placeholder="Share your thoughts here..."
          maxLength="500"
          onChange={this.handleInputChange}
        ></textarea>
        <p>You may also reach out to aoehippo.contact@gmail.com if you wish!</p>
        <input id="submit" type="submit" value="Submit"></input>
      </form>
    );
    const formResponse = <p class="form-response">{this.state.formResponse}</p>;
    const loadingSpinner = (
      <div>
        <div class="loader"></div>
        <p class="form-response">Submitting form...</p>
      </div>
    );
    return (
      <div class="page-container">
        {/* HTML Meta tags */}
        <Helmet>
          <title>aoehippo | Feedback</title>
          <meta
            name="description"
            content="Thank you for using the hotkey training tool. Let us know how we can improve!"
          />
        </Helmet>
        {/* Naviation bar at the top of the page */}
        <NavBar />

        <div class="feedback main-content">
          {shouldDisplayForm
            ? feedbackForm
            : this.state.formResponse !== null
            ? formResponse
            : loadingSpinner}
        </div>

        <footer class="main-footer">
          <h3>@ 2022 aoehippo</h3>
        </footer>
      </div>
    );
  }
}

export default Feedback;
